import React from 'react'
import {
  FaGraduationCap,
  // FaPaperPlane,
  FaBusinessTime,
  FaLaptopCode,
} from 'react-icons/fa'

// import GraceHopper from './../assets/images/graduation.jpg'
import Rotunda from './../assets/images/Rotunda.jpg'
// import Image3 from './../assets/images/image3.jpg'

const data = [
  {
    date: 'June 2019 - Present',
    headline: 'Software Engineer',
    subheadline: 'Grailed',
    description: `A peer-to-peer marketplace for men’s luxury fashion and streetwear.`,
    icon: <FaLaptopCode />,
  },
  {
    date: 'February 2019 - May 2019',
    headline: 'Software Engineering Teaching Fellow',
    subheadline: 'Grace Hopper Program - Fullstack Academy',
    description: `Graduated from the Grace Hopper Program at Fullstack Academy and began the Teaching Fellowship.`,
    // image: GraceHopper,
    icon: <FaLaptopCode />,
  },
  {
    date: 'October 2018 - February 2019',
    headline: 'Software Engineering Immersive Student',
    subheadline: 'Grace Hopper Program - Fullstack Academy',
    description: `Completed an 18-week immersive Software Engineering program at Fullstack Academy.`,
    // image: GraceHopper,
    icon: <FaGraduationCap />,
  },

  {
    date: '2015 - 2018',
    headline: 'Special Projects Manager, Marketing & Logistics',
    subheadline: 'Crush Wine & Spirits',
    description: `• Collaborated on NetSuite implementation, led staff training
    • Initiated and directed Magento implementation
    • Managed foreign wine procurement and logistics`,
    // image: Image2,
    icon: <FaBusinessTime />,
  },

  {
    date: '2007 - 2015',
    headline: 'Operations & Marketing Specialist',
    subheadline: 'Crush Wine & Spirits',
    description: `• Standardized corporate gift program, led support staff of 8+
    • Managed partnership with luxury credit card benefits program, produced content and assets`,
    // image: Image3,
    icon: <FaBusinessTime />,
  },

  {
    // date: '2003',
    headline: 'University of Virginia',
    subheadline: 'Charlottesville, VA',
    description: `Graduated from the University of Virginia. Major: Latin`,
    image: Rotunda,
    icon: <FaGraduationCap />,
  },
]

export default data
